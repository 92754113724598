import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { ErrorPanel } from 'components/StatusPanel';

export const ErrorPanelWithMargin = styled(ErrorPanel)`
  margin: 0 0 ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const DivWithTopMargin = styled.div`
  margin-top: ${spacing(3)};
`;

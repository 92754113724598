import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import useRetrieveQuote from 'apiHelpers/quote/useRetrieveQuote';
import { AxiosError } from 'axios';
import { isQuoteNotFoundError } from 'businessLogic/errors/isQuoteNotFoundError';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from 'components/Layout';
import RichTextWithModal from 'components/RichTextWithModal';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import RetrieveQuoteReferenceForm from 'forms/RetrieveQuoteReferenceForm';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackAPIError } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import useLoadingState from 'helpers/useLoadingState';
import { DivWithTopMargin, ErrorPanelWithMargin } from './styles';
import { StyledRichText } from '../retrieve-quote/styles';

type RetrieveQuoteReferenceProps = {
  data: {
    csPetRetrieveYourQuote: {
      meta_title: string;
      hero: {
        heading: string;
        subheading: string;
      };
      intro_text: string;
      error_messages: {
        not_found: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetRetrieveYourQuote {
      meta_title
      hero {
        heading
        subheading
      }
      intro_text
      error_messages {
        not_found
      }
    }
  }
`;

const RetrieveQuoteReference: React.FC<RetrieveQuoteReferenceProps> = ({
  data: {
    csPetRetrieveYourQuote: {
      meta_title,
      hero: { heading, subheading },
      intro_text,
      error_messages,
    },
  },
}) => {
  usePageTracking(meta_title);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking();
  const { retrieveQuoteByInputReferenceAndNavigate } = useRetrieveQuote();
  const { isLoading, withLoadingState } = useLoadingState();
  const [errorPanelText, setErrorPanelText] = useState<string>('');
  const errorHandler = useDefaultErrorHandling();
  return (
    <Layout
      pageTitle={PageTitle.RetrieveQuoteReference}
      heading={heading}
      subheading={subheading}
      metaTitle={meta_title}
      sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY}>
      {isLoading && <LoadingOverlay loadingMessage="Retrieving quote, please wait" />}
      <StyledRichText html={intro_text} />
      <DivWithTopMargin>
        {errorPanelText && (
          <FieldGrid alignLeft>
            <ErrorPanelWithMargin data-cy="retrieve-quote-error-panel">
              <RichTextWithModal
                pageTitle={PageTitle.RetrieveQuoteReference}
                aria-live="assertive"
                html={errorPanelText}
              />
            </ErrorPanelWithMargin>
          </FieldGrid>
        )}
        <RetrieveQuoteReferenceForm
          moveNext={async () => {
            try {
              setErrorPanelText('');
              await withLoadingState(retrieveQuoteByInputReferenceAndNavigate);
            } catch (err) {
              const error: AxiosError = err as AxiosError;
              if (isAxiosError(error) && isQuoteNotFoundError(error)) {
                trackAPIError(error);
                setErrorPanelText(error_messages.not_found);
              } else {
                errorHandler(error);
              }
            }
          }}
        />
      </DivWithTopMargin>
    </Layout>
  );
};

export default RetrieveQuoteReference;
